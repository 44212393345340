html, body, input, select {
    font-family: "DIN Regular";
}

.template-ap.container-fluid {
    max-width: 1280px;
    font-family: "DIN Regular";
}

.button-secondary-outline-ap {
    background-color: #ffffff;
    color: #17a0d5;
    border: 1px solid #17a0d5;
    cursor: pointer;
    height: 72px;
}

.button-primary-solid-ap {
    cursor: pointer;
    height: 72px;
}

.button-back,
.button-next{
    min-width: 12rem;
}

input[type="number"] {
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
  }

.info-header-wrapper {
    background-color: rgb(238,238,238);
    padding: 3rem 3rem;
    margin-bottom: 2rem;
}

@media (max-width: 768px) {

    .info-header-wrapper {
        padding: inherit;
        margin-bottom: inherit;
    }
}