.bg-page {
    background-color: #eeeeef;
}

.legaltxt {
    margin: 0;
    size: 14px;
    color: #6e6e6e;
    line-height: 17px;
    text-align: left;
    align-self: center;
    width: 80%;
}

.plans-container .plan-name{
    text-align: center;
}

@media (max-width: 768px) {
    .legaltxt {
        font-size: 10px;
    }
}