.payment-container {
  background-color: rgb(238, 238, 239);
  color: #575756;
  font-family: "DIN Regular";
}

.payment-container .payment-title {
  font-size: 25px;
}

.payment-container .payment-subtitle {
  font-size: 20px;
  font-family: "DIN Bold";
}

.payment-container .payment-description {
  font-size: 12px;
}

.payment-container .payment-description a {
  font-size: 12px;
  color: #17a0d5;
  font-weight: bold;
}

.payment-container .payment-button-modify {
  background-color: #ffffff;
  color: #17a0d5;
  border: 1px solid #17a0d5;
  cursor: pointer;
  background-image: url("../../Assets/Icons/modify-payment-button-icon.svg");
  background-repeat: no-repeat;
  background-position: 3rem 50%;
}

.payment-container .payment-button-wrapper-modify {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.payment-container .payment-button-wrapper-back-pay {
  display: flex;
  justify-content: space-between;
}

.payment-container .button-back {
  background-color: #ffffff;
  color: #17a0d5;
  border: 1px solid #17a0d5;
  cursor: pointer;
}

.payment-container .button-pay {
  cursor: pointer;
}


@media (max-width: 768px) {
  .payment-container .payment-button-wrapper-modify {
    grid-template-columns: 1rem 1fr 1rem
  }

  .payment-container .payment-button-modify {
    background-position: 1rem 50%;
    font-size: 15px;
    grid-column: 2/3;
  }

  .payment-container .info-header-container{
    padding: inherit;
    margin-bottom: inherit;
  }

  .payment-container .payment-button-wrapper-back-pay {
    display: inline-grid;
    width: 100%;
    justify-content: center;
  }

  .payment-banner-container .payment-privacy-banner-container{
    grid-template-columns: repeat(1, 1fr) !important;
  }

}