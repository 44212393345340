

.modal-form-error-container.modal-bg{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3);
    z-index: 99999;
    position: fixed;
}
.modal-form-error-container .modal-content{
    position: fixed;
    top: 50%;
    width: 70vw;
    margin-left: -35vw;
    margin-top: -15vh;
    background-color: white;
    z-index: 999999999;
    left: 50%;
    padding: 1rem 2rem;
    text-align: center;
}
.error-item{
    text-align: left;
}

@media (min-width: 1800px) {
    .modal-form-error-container .modal-content{
        max-width: 1200px;
        margin-left: -600px;
    }
}

@media (max-width: 991px) {
    .modal-form-error-container .modal-content{
        top: 50%;
        width: calc(100% - 2rem);
        left: initial;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}