.beneficiary-container {
    /* background-color: #eeeeef; */
    margin-top: 2rem;
}

.beneficiary-container .beneficiary-title {
    font-weight: bold;
    font-size: 25px;
    text-align: left;
    padding-bottom: 2rem;
}

.beneficiary-container .beneficiary-subtitle {
    color: #575756;
    text-align: left;
    font-size: 25px;
    padding-bottom: 2rem;
}

.beneficiary-container .beneficiary-insured-subtitle {
    color: #575756;
    text-align: left;
    font-size: 20px;
    padding-bottom: 2rem;
}

.check-label {
    align-self: center;
    color: #575756;
    font-size: 20px;
}

.check-label-legal, .percentage-desc {
    color: #575756;
    font-size: 12px;
}

.trash-icon {
    cursor: pointer;
}
  
/* mobile */
@media (max-width: 768px) {
    .beneficiary-container {
        margin-top: 5rem;
    }

    .beneficiary-container .beneficiary-title {
        font-size: 20px;
        padding-bottom: 1rem;
    }

    .beneficiary-container .beneficiary-subtitle {
        font-size: 20px;
        padding-bottom: 1rem;
    }

    .beneficiary-container .beneficiary-insured-subtitle {
        font-size: 20px;
        padding-bottom: 1rem;
    }
}