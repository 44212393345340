.insureds-container {
    /* background-color: #eeeeef; */
    margin-bottom: 2rem;
}

.insureds-container .insureds-title {
    font-weight: bold;
    font-size: 25px;
    text-align: left;
    padding-bottom: 2rem;
}

.insureds-container .insured-subtitle {
    color: #575756;
    font-size: 25px;
    text-align: left;
    padding-bottom: 2rem;
    font-family: "DIN Medium";
}

.insured-container {
    background-color: #eeeeef;
}

.insured-container .insured-title {
    color: #575756;
    text-align: left;
    font-size: 36px;
    padding-bottom: 2rem;
}

.dateTextLegal{
    font-size: 12px;
    color: #575756;
}

  
/* mobile */
@media (max-width: 768px) {
    .insureds-container .insureds-title-mobile {
        font-size: 20px;
        padding-bottom: 1rem;
    }

    .insured-container .insured-title-mobile {
        font-size: 20px;
        padding-bottom: 1rem;
    }

    .insured-container .insured-subtitle-mobile {
        font-size: 20px;
        padding-bottom: 1rem;
    }
}